<template>
    <div class="main">
        <main-navbar @toggle="toggle" />
        <mobile-navbar :show="showMenu" @toggle="toggle" />
        <div class="main__content">
            <router-view />
        </div>
        <main-footer />
        <scroll-top-button />
    </div>
</template>

<script>
import MainNavbar from '@/components/layouts/main/Navbar.vue';
import MainFooter from '@/components/layouts/main/Footer.vue';
import MobileNavbar from '@/components/layouts/main/MobileNavbar.vue';
import ScrollTopButton from "@/components/ui/ScrollTopButton.vue";

export default {
    name: 'main-layout',
    components: {
        MainNavbar,
        MainFooter,
        MobileNavbar,
        ScrollTopButton,
    },
    data() {
        return {
            showMenu: false,
        };
    },
    methods: {
        toggle() {
            this.showMenu = !this.showMenu;
        },
    },
};
</script>
