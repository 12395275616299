<template>
    <footer class="footer bg-light">
        <div class="container">
            <div class="row">
                <div class="col-md-6 d-flex align-items-center justify-content-center justify-md-content-start">
                    <span class="footer__copy">&copy; {{ new Date().getFullYear() }} Accounts. {{ $t('All rights reserved') }}</span>
                </div>
                <div class="col-md-6 d-flex align-items-center justify-content-center justify-md-content-end">
                    <ul class="nav">
                        <li class="nav-item footer__nav-item">
                            <a v-sanitize-href="$t('accounts.privacy-policy')" target="_blank" class="nav-link footer__nav-link">
                                {{ $t('Privacy notice') }}
                            </a>
                        </li>
                        <li class="nav-item footer__nav-item">
                            <a v-sanitize-href="$t('accounts.terms-and-conditions')" target="_blank" class="nav-link footer__nav-link">
                                {{ $t('Terms and conditions') }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
}
</script>

<style lang="scss">
.footer {
    padding-top: 45px;
    padding-bottom: 45px;

    &__copy {
        color: #AAAAAA;

        @media(max-width: 768px) {
            margin-bottom: 15px;
        }
    }

    &__nav-item {
        position: relative;

        &:not(:last-child) {
            &::after {
                content: '';
                position: absolute;
                right: 0;
                top: calc(50% - 7.5px);
                height: 15px;
                width: 2px;
                background: #ECECEC;

            }
        }
    }

    &__nav-link {
        font-weight: 500;
        color: #AAAAAA;
    }
}
</style>
