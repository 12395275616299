<template>
    <b-navbar
        ref="mainNavbar"
        toggleable="lg"
        fixed="top"
        class="main-navbar"
        :class="{ 'main-navbar--scrolled': scrolled }"
    >
        <div class="main-navbar__container container" :class="{ 'main-navbar__container--scrolled': scrolled }">
            <b-navbar-brand to="/">
                <img src="@/assets/images/logos/accounts-logo.svg" class="main-navbar__logo" alt="Tendencys" />
            </b-navbar-brand>
            <button class="main-navbar__toggle-button d-lg-none" @click="$emit('toggle')">
                <span class="fas fa-bars"></span>
            </button>
            <b-collapse is-nav>
                <ul class="navbar-nav ml-auto">
                    <li class="main-navbar__nav-item nav-item">
                        <ui-button to="/signup" variant="secondary">
                            {{ $t('Signup') }}
                        </ui-button>
                    </li>
                    <li class="main-navbar__nav-item nav-item">
                        <ui-button to="/login" variant="primary">
                            {{ $t('Login') }}
                        </ui-button>
                    </li>
                    <li class="main-navbar__nav-item nav-item">
                        <languages variant="link" class="main-navbar__link" toggle-class="main-navbar__nav-link nav-link" />
                    </li>
                </ul>
            </b-collapse>
        </div>
    </b-navbar>
</template>

<script>
import Languages from "@/components/Languages";
import UiButton from '@/components/ui/buttons/Button.vue';

export default {
    name: 'Navbar',
    components: { Languages, UiButton },
    data() {
        return {
            scrolled: false,
        };
    },
    created  () {
        window.document.body.addEventListener('scroll', this.handleScroll);
    },
    destroyed  () {
        window.document.body.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll (event) {
            const isHigher = event.target.scrollTop > this.$refs.mainNavbar.$el.clientHeight;

            if (isHigher && !this.scrolled) {
                this.scrolled = true;
            }
            
            if (!isHigher && this.scrolled) {
                this.scrolled = false;
            }
        }
    }
};
</script>

<style lang="scss">
.main-navbar {
    height: 95px;
    background-color: #F5F6F7;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    transition: box-shadow 500ms;

    &--scrolled {
        background-color: #ffffff;
        box-shadow: 0 3px 6px rgba(#000000, 0.16);
    }

    &__container {
        height: 100%;
        border-bottom: 2px solid #EEEEEE;

        &--scrolled {
            border-color: #ffffff;
        }
    }

    &__logo {
        width: 150px;
        height: auto;

        &--scrolled {
            height: 50px;
            width: auto;
        }
    }

    &__toggle-button {
        font-size: 25px;
        background: none;
        border: none;
        color: #004C74;
    }
    
    &__nav-item {
        display: flex;        
        align-items: center;

        &:not(:last-child) {
            margin-right: 16px;
        }
    }

    &__nav-link {
        font-weight: 500 !important;
        font-size: 16px;
        color: #202225 !important;
    }
}
</style>
