<template>
    <div class="mobile-navbar">
        <div class="mobile-navbar__content" :class="{ 'mobile-navbar__content--show': show }">
            <img
                src="@/assets/images/logos/accounts-logo.svg"
                class="mobile-navbar__logo img-fluid"
                alt="Tendencys"
            />

            <ul class="nav flex-column">
                <li class="mobile-navbar__nav-item nav-item">
                    <a class="mobile-navbar__nav-link nav-link" href="https://ecartpay.com/privacy-policy">
                        {{ $t('Privacy notice') }}
                    </a>
                </li>
                <li class="mobile-navbar__nav-item nav-item">
                    <a class="mobile-navbar__nav-link nav-link" href="https://ecartpay.com/terms-and-conditions">
                        {{ $t('Terms and conditions') }}
                    </a>
                </li>
                <li class="mobile-navbar__nav-item nav-item">
                    <languages variant="link" class="mobile-navbar__link" toggle-class="mobile-navbar__nav-link nav-link" />
                </li>
                <li class="mobile-navbar__nav-item nav-item">
                    <router-link to="/signup" class="mobile-navbar__button mobile-navbar__button--secondary">
                        {{ $t('Signup') }}
                    </router-link>
                </li>
                <li class="mobile-navbar__nav-item nav-item">
                    <router-link to="/login" class="mobile-navbar__button mobile-navbar__button--primary">
                        {{ $t('Login') }}
                    </router-link>
                </li>
            </ul>
        </div>
        <div class="mobile-navbar__overlay" :class="{ 'mobile-navbar__overlay--show': show }" @click="$emit('toggle')"></div>
    </div>
</template>

<script>
import Languages from "@/components/Languages";

export default {
    name: 'MobileNavbar',
    components: { Languages },
    props: {
        show: {
            type: Boolean,
            default: false,
            required: true,
        },
    },
};
</script>

<style lang="scss">
.mobile-navbar {
    text-align: center;

    &__content {
        transition: ease-out 0.3s;
        position: fixed;
        top: 0;
        left: 0;
        background: #F5F6F7;
        width: 80%;
        max-width: 400px;
        height: 100%;
        z-index: 9999;
        padding: 2rem 1rem;
        transform: translateX(-100%);
        
        &--show {
            transform: translateX(0);

            @media(min-width: 992px) {
                display: none;
            }
        }
    }

    &__logo {
        width: 180px;
        margin-bottom: 35px;
    }

    &__nav-item {
        margin-bottom: 20px;
    }

    &__nav-link {
        font-weight: 500 !important;
        font-size: 18px !important;
        color: #202225 !important;
    }

    &__button {
        display: inline-block;
        color: #202225;
        padding: 12px 36px;
        border-radius: 20px;
        font-weight: 500;
        font-size: 18px;
        width: 100%;

        &--primary {
            border: 1px solid #004C74;
            background-color: #004C74;
            color: white;
        }

        &--secondary {
            border: 1px solid #EEEEEE;
            background-color: #EEEEEE;
        }
    }

    &__overlay {
        background: rgba(0, 0, 0, 0.6);
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 9998;
        display: none;

        &--show {
            display: block;

            @media(min-width: 992px) {
                display: none;
            }
        }
    }
}
</style>
