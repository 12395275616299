<template>
    <button type="button" class="scroll-top-button" :class="{ 'scroll-top-button--show': scrolled }" @click="scrollTop">
        <span class="fas fa-chevron-up"></span>
    </button>
</template>

<script>
export default {
    name: 'ScrollTopButton',
    data() {
        return {
            scrolled: false,
        };
    },
    created  () {
        window.document.body.addEventListener('scroll', this.handleScroll);
    },
    destroyed  () {
        window.document.body.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll (event) {
            const isHigher = event.target.scrollTop > 300;

            if (isHigher && !this.scrolled) {
                this.scrolled = true;
            }
            
            if (!isHigher && this.scrolled) {
                this.scrolled = false;
            }
        },
        scrollTop() {
            window.document.body.scrollTo({ top: 0, behavior: 'smooth' });
        },
    },
};
</script>

<style lang="scss" scoped>
.scroll-top-button {
    align-items: center;
    background-color: #004C74;
    border: none;
    border-radius: 100%;
    bottom: 35px;
    color: #ffffff;
    display: flex;
    height: 60px;
    justify-content: center;
    opacity: 0;
    position: fixed;
    right: 30px;
    transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
    visibility: hidden;
    width: 60px;
    z-index: 999;

    &:hover {
        background-color: #00659b;
    }

    &--show {
        opacity: 1;
        visibility: visible;
    }
}
</style>
